<template>
  <b-container fluid>
    <!-- Divider -->
    <b-row>
      <b-col sm="2">
        <sidebarcontent />
      </b-col>
      <b-col sm="10">
        <!-- SECTION LANDING SECTION MOBILE -->
        <b-row v-if="isMobile">
          <b-col cols="12" class="m-0 p-0 ">
            <!-- NOTE Landing-video -->
            <div class="wrapper">
              <div class="gradient"></div>
              <video ref="landingVideo" loop muted>
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/videos%2Fapplynowbg.mp4?alt=media&token=d06a9292-68d1-4841-a6e3-c13b57690de4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div
              style="z-index: 99"
              class="col-12 d-flex flex-column justify-content-between p-0 m-0"
            >
              <b-container fluid class="p-0 m-0">
                <b-row class="p-0 m-0 fullH">
                  <b-col class="d-flex flex-column  " cols="12">
                    <b-row class="py-3 ">
                      <b-col
                        cols="6"
                        class="d-flex flex-column justify-content-center"
                      >
                        <b-icon
                          variant="light"
                          class="h1"
                          style="margin:0"
                          v-b-toggle.sidebar-backdrop
                          icon="list"
                        ></b-icon>
                      </b-col>
                      <b-col cols="6">
                        <b-button size="lg" variant="danger"
                          >Aplica ahora</b-button
                        >
                      </b-col>
                    </b-row>
                    <b-row style="height:100%">
                      <b-col class="d-flex flex-column justify-content-center">
                        <b-row>
                          <b-col cols="12">
                            <h1 class="text-white text-uppercase mx-2">
                              <b>Girl Power Talk </b>
                              <span class="text-danger">Mexico</span>
                            </h1>
                            <p
                              class="h5 font-weight-light text-white mx-2 pt-2"
                            >
                              ¿Quieres formar parte de una empresa que nació con
                              el objetivo de formar una mejor sociedad en
                              México?
                            </p>
                          </b-col>

                          <b-col
                            cols="6"
                            class="d-flex flex-column justify-content-center mt-5"
                          >
                            <p
                              v-show="!playingVideo"
                              @click="playPauseVideo"
                              class="h1 mb-2  "
                            >
                              <b-icon variant="light" icon="play-fill"></b-icon>
                            </p>
                            <p
                              v-show="playingVideo"
                              @click="playPauseVideo"
                              class="h1 mb-2  "
                            >
                              <b-icon
                                variant="light"
                                icon="pause-fill"
                              ></b-icon>
                            </p>
                          </b-col>
                          <b-col>
                            <!-- <sidebarcontent/> -->
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <!-- </div> -->
          </b-col>
        </b-row>
        <!-- SECTION LANDING SECTION DESKTOP -->
        <b-row v-if="!isMobile">
          <b-col cols="12" class="m-0 p-0 ">
            <!-- <div class=" video-container m-0 "> -->
            <!-- NOTE Landing-video -->
            <div class="wrapper">
              <div class="gradient"></div>
              <video ref="landingVideo" loop muted>
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/videos%2Fapplynowbg.mp4?alt=media&token=d06a9292-68d1-4841-a6e3-c13b57690de4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div
              style="z-index: 99"
              class="col-12 d-flex flex-column justify-content-between p-0 m-0"
            >
              <b-container fluid class="p-0 m-0">
                <b-row class="p-0 m-0 fullH">
                  <b-col cols="3">
                    <!-- <sidebarcontent/> -->
                  </b-col>
                  <b-col
                    class="d-flex flex-column justify-content-between "
                    cols="9"
                  >
                    <div class="d-flex flex-row justify-content-end mt-4">
                      <div class="d-none d-sm-block btn btn-danger mr-5">
                        <router-link to="/apply" class="text-white">
                          Únete al equipo
                        </router-link>
                      </div>
                    </div>
                    <div class="mb-0 ">
                      <b-container>
                        <b-row>
                          <b-col cols="6">
                            <h1 class="text-white text-uppercase ml-5">
                              <b>Girl Power Talk </b>
                              <span class="text-danger">Mexico</span>
                            </h1>
                            <p
                              class="ml-5 h5 font-weight-light text-white mb-5"
                            >
                              ¿Quieres formar parte de una empresa que nació con
                              el objetivo de formar una mejor sociedad en
                              México?
                            </p>
                          </b-col>
                          <b-col
                            cols="6"
                            class="d-flex flex-column justify-content-center"
                          >
                            <p
                              v-show="!playingVideo"
                              @click="playPauseVideo"
                              class="h1 mb-2  "
                            >
                              <b-icon variant="light" icon="play-fill"></b-icon>
                            </p>
                            <p
                              v-show="playingVideo"
                              @click="playPauseVideo"
                              class="h1 mb-2  "
                            >
                              <b-icon
                                variant="light"
                                icon="pause-fill"
                              ></b-icon>
                            </p>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <!-- </div> -->
          </b-col>
        </b-row>
        <!-- SECTION name form -->
        <b-row class="my-5">
          <b-col>
            <b-container>
              <b-row class="mb-5">
                <b-col sm="12 d-flex flex-row justify-content-center">
                  <div>
                    <p class="h1 text-center">
                      Únete a nuestro equipo <br />
                      <small>Queremos saber de ti.</small>
                    </p>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    description="Nunca compartiremos tu información con nadie."
                  >
                    <!-- TODO Añadir las validaciones usando state -->
                    <b-form-input
                      v-model="personalData.name"
                      type="text"
                      placeholder="Tu nombre"
                      required
                      :state="personalData.name.length > 3"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <b-form-input
                      v-model="personalData.email"
                      placeholder="Tu email"
                      required
                      type="email"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <b-form-input
                      v-model="personalData.age"
                      type="number"
                      placeholder="Tu edad"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <b-form-input
                      v-model="personalData.phone_number"
                      type="text"
                      placeholder="Un número telefonico"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <label class="form-check-label" for="input-form-field"
                      >Sube tu CV o algún documento de interés</label
                    >
                    <b-form-file
                      id="input-form-field"
                      class="mt-1"
                      v-model="uploadedFiled"
                      :state="Boolean(uploadedFiled)"
                      ref="myInput"
                      placeholder="Carga un archivo"
                      drop-placeholder="Suelta un archivo aquí"
                    ></b-form-file>
                    <div class="form-text">
                      <small>
                        El tamaño de tu archivo debe ser máximo de 10 MB
                      </small>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <!-- SECTION  form header -->
        <b-row style="height:20em" class="mt-5 bg-danger mb-3 custom-bg">
          <b-col sm="12">
            <b-container
              fluid
              class="h-100 d-flex flex-column justify-content-center p-0"
            >
              <b-row>
                <b-col sm="6" class="p-0">
                  <div
                    v-if="isMobile"
                    class="title d-flex flex-row bg-light mb-3"
                  >
                    <p
                      class="h3 text-uppercase mx-auto align-self-center font-weight-light"
                    >
                      girl
                      <span class="font-weight-bold text-dark">power</span> talk
                      <span class="font-weight-bold text-danger">méxico</span>
                    </p>
                  </div>
                  <div
                    v-if="!isMobile"
                    class="title d-flex flex-row-reverse bg-light"
                  >
                    <p
                      class="h3 text-uppercase mr-5 align-self-center font-weight-light"
                    >
                      girl
                      <span class="font-weight-bold text-dark">power</span> talk
                      <span class="font-weight-bold text-danger">méxico</span>
                    </p>
                  </div>
                </b-col>
                <b-col sm="6">
                  <div
                    style="height:4em"
                    class="d-flex flex-row ml-5"
                    :class="{
                      'mx-auto': isMobile,
                      'justify-content-center': isMobile,
                    }"
                  >
                    <p
                      class="h3 font-weight-bold text-uppercase align-self-center text-white"
                    >
                      ¡únete!
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <!-- SECTION sticky progress bar  -->
        <b-row v-if="!isMobile" style="height:7em;" class="sticky-top bg-light">
          <b-col class="d-flex flex-column justify-content-center">
            <div class="container  ">
              <div class="row ">
                <div class="col-11 p-0">
                  <div class="d-flex flex-row justify-content-around my-1">
                    <p
                      class="text-uppercase h5 section-to-reach"
                      :class="{ 'section-reached': this.bar.value > 0 }"
                    >
                      queremos saber de ti
                    </p>
                    <p
                      class="text-uppercase h5 section-to-reach"
                      :class="{
                        'section-reached': this.bar.value > 50,
                      }"
                    >
                      influencias y metas
                    </p>
                    <p
                      class="text-uppercase h5 section-to-reach"
                      :class="{
                        'section-reached': this.bar.value == 100,
                      }"
                    >
                      aptitudes
                    </p>
                  </div>
                  <b-progress
                    class="m-auto"
                    :value="bar.value"
                    :variant="bar.variant"
                    :key="bar.variant"
                  ></b-progress>
                </div>
                <div class="col-1 p-0">
                  <div>
                    <b-icon
                      class="mt-1"
                      style="height:5em;width: 5em; opacity: 0.1"
                      :class="{ completedForm: this.bar.value == 100 }"
                      icon="patch-check"
                    ></b-icon>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- SECTION sticky progress bar MOBILE -->
        <b-row
          v-if="isMobile"
          style="height:7em; border-radius: 50px"
          class="sticky-top bg-light"
        >
          <b-col class="d-flex flex-column justify-content-center">
            <div class="container  ">
              <div class="row ">
                <div class="col-10 p-0">
                  <div class="d-flex flex-row justify-content-around my-1">
                    <p
                      class="text-uppercase  section-to-reach mx-2"
                      style="font-size: x-small"
                      :class="{ 'section-reached': this.bar.value > 0 }"
                    >
                      queremos saber de ti
                    </p>
                    <p
                      class="text-uppercase section-to-reach mx-2"
                      style="font-size: x-small"
                      :class="{
                        'section-reached': this.bar.value > 50,
                      }"
                    >
                      influencias y metas
                    </p>
                    <p
                      class="text-uppercase section-to-reach mx-2"
                      style="font-size: x-small"
                      :class="{
                        'section-reached': this.bar.value == 100,
                      }"
                    >
                      aptitudes
                    </p>
                  </div>
                  <b-progress
                    class="m-auto"
                    :value="bar.value"
                    :variant="bar.variant"
                    :key="bar.variant"
                  ></b-progress>
                </div>
                <div class="col-2 p-0">
                  <div>
                    <b-icon
                      class="mt-5 "
                      style="height:2em;width: 2em; opacity: 0.2"
                      :class="{ completedForm: this.bar.value == 100 }"
                      icon="patch-check"
                    ></b-icon>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- SECTION complete form -->
        <b-row style="min-height: 20em;">
          <b-col class="m-3 ">
            <b-container>
              <b-row>
                <b-col sm="12" class="my-5">
                  <div
                    v-if="isMobile"
                    style="border-radius: 6px"
                    class="d-flex flex-row p-2 bg-remember"
                  >
                    <div>
                      <b-icon
                        style="height:5em;width: 5em;color:#ffffff"
                        icon="exclamation-circle"
                      ></b-icon>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <p class="h5 mx-4 text-white font-weight-light">
                        Recuerda que este formulario tiene una función de
                        autoguardado, puedes <span>avanzar a tu ritmo</span> y
                        retomarlo cuando gustes.
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="!isMobile"
                    style="width:70%; border-radius: 50px"
                    class="d-flex flex-row p-2 bg-remember"
                  >
                    <div>
                      <b-icon
                        style="height:5em;width: 5em;color:#ffffff"
                        icon="exclamation-circle"
                      ></b-icon>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <p class="h5 mx-4 text-white">
                        Recuerda que este formulario tiene una función de
                        autoguardado, puedes <span>avanzar a tu ritmo</span> y
                        retomarlo cuando gustes.
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="my-5">
                <b-container>
                  <b-row
                    v-for="(pregunta, index) in form"
                    :key="index"
                    class="my-5"
                  >
                    <b-col sm="12">
                      <p class="h4 font-weight-bold">
                        {{ pregunta.question }}<br />
                        <span
                          ><small>{{ pregunta.small }}</small></span
                        >
                      </p>
                    </b-col>
                    <b-col sm="12 mt-4">
                      <b-form-textarea
                        @change="getProgress"
                        placeholder="Tu respuesta aquí"
                        rows="3"
                        max-rows="8"
                        v-model="form[index].answer"
                      ></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-container>
              </b-row>
              <b-row>
                <b-col
                  sm="12"
                  class="d-flex flex-row justify-content-center my-5"
                >
                  <div class="container">
                    <div class="row my-2">
                      <div class="col-12">
                        <b-button
                          v-b-modal.modal-1
                          @click="showAlert"
                          variant="primary"
                          class="mx-5"
                          :disabled="hideSubmit"
                          >Enviar respuestas</b-button
                        >
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12">
                        <button @click="cleanForm" class="btn btn-danger mx-5">
                          Limpiar todos los campos
                        </button>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <!-- SECTION submit form and alert -->
        <b-row>
          <b-col sm="12 d-flex flex-row justify-content-center">
            <b-modal id="modal-1" ref="my-modal" hide-header hide-footer>
              <b-alert
                :show="dismissCountDown"
                dismissible
                variant="warning"
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
              >
                <p class="h3">¡Gracias por postularte!</p>
                <p class="h5">
                  Nuestro equipo de
                  <span class="font-weight-bold"
                    >GPT <span class="text-danger">México</span>
                  </span>
                  revisará tus respuestas y se pondrá en contacto contigo.
                </p>
                <p class="my-5 d-flex flex-row justify-content-center">
                  <img
                    style="height:1.5em"
                    src="/Media/Icons/gpt-logo.png"
                    alt=""
                  />
                </p>
                <p class="my-3">
                  Este recuadro se cerrará automáticamente en
                  {{ dismissCountDown }} segundos...
                </p>
                <b-progress
                  variant="warning"
                  :max="dismissSecs"
                  :value="dismissCountDown"
                  height="4px"
                ></b-progress>
              </b-alert>
            </b-modal>
          </b-col>
        </b-row>
        <!-- SECTION GET IN TOUCH  -->
        <b-row class="get-in-touch-bg">
          <b-col
            style="height:14em"
            class="d-flex flex-column justify-content-center "
          >
            <div class="container">
              <div class="row my-3">
                <div class="col-12">
                  <p class="h3" :class="{ h5: isMobile }">
                    Si tienes una historia inspiradora, contáctanos.
                    #GirlPowerTalkMexico #GPTMexico
                  </p>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-12">
                  <div class="btn btn-outline-danger">
                    <a href="#contacto" class="text-danger custom-link"
                      >Contáctanos</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- SECTION contact form -->
        <b-row class="mt-5">
          <b-col sm="12">
            <contactform />
          </b-col>
        </b-row>
        <!-- SECTION footer -->
        <b-row>
          <b-col sm="12" class="m-o p-0">
            <footerComponent />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import contactform from "@/components/ContactForm.vue";
import footerComponent from "@/components/Footer.vue";
import sidebarcontent from "@/components/SidebarContent.vue";
// Storage key form
const formKey = "FORM_KEY";
const personalDataKey = "PERSONAL_DATA_KEY";
export default {
  name: "apply-form",
  components: { sidebarcontent, contactform, footerComponent },
  data() {
    return {
      uploadedFiled: null,
      hideSubmit: true,
      showModal: false,
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      isMobile: false,
      playingVideo: false,
      personalData: {
        email: "",
        name: "",
        age: "",
        file: null,
        phone_number: "",
      },
      form: [
        {
          question: `
                    1. ¿Cómo te enteraste acerca de Girl Power Talk México? `,
          small: `Si alguien te lo comentó, por favor dinos su nombre.`,
          answer: null,
        },
        {
          question: `
                    2. ¿Para qué puesto deseas aplicar?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    3. ¿Has recibido mentoría a lo largo de tu vida? Si sí, ¡cuéntanos acerca de quienes te la dieron!
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    4. ¿Qué personas de la historia admiras?

                  `,
          small: `Pueden estar vivas o haber vivido en tiempos pasados.`,
          answer: null,
        },
        {
          question: `
                    5. El liderazgo se manifiesta de diversas formas. ¿Cuáles son las cualidades que te han permitido manifestarlo?

                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    6. Cuéntanos acerca de alguna experiencia que hayas tenido como líder. ¿Cómo lograste que las personas que liderabas mantuvieran su productividad y sobre todo que estuvieran unidas?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    7. ¿Cuáles son tus fortalezas principales? Describe al menos dos de ellas y cómo las usarías para crear un mundo mejor.

                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    8. Escribe una breve autobiografía de entre 300 y 400 palabras. ¡Utiliza tu creatividad!
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    9. ¿Cuál es tu frase preferida y qué significa para ti?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    10. ¿Qué significa para ti la autoconfianza?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    11. ¿Qué grandes retos o dificultades has encontrado? ¡Cuéntanos cómo los enfrentaste!
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    12. ¿Cuál es el logro más grande que has alcanzado?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    13. ¿Qué habilidades deseas aprender y desarrollar dentro de Girl Power Talk México?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    14. “Que ellas son capaces de todos los entusiasmos y que los sentimientos de la gloria y libertad no les son unos sentimientos extraños” -Leona Vicario. ¿Qué significa para ti esta frase y cómo crees que podrías aplicarla en tu vida diaria?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    15.  Todas las personas tienen debilidades y hay que ser humilde para reconocerlas. ¿Cuáles son las tuyas?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    16. ¿Cuál crees que es la principal dificultad que enfrentan las mujeres en México? Si pudieras enfrentarla y superarla, ¿cómo lo harías?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `

                    17. ¿Cuál es tu libro favorito y por qué? ¿Qué aprendiste de él y cómo lo has aplicado en tu vida?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    18. ¿Qué te gustaría que supiéramos acerca de ti?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    19. Si tuvieras una varita mágica, ¿qué cualidades te gustaría que tuvieran las personas con las que colaborarás?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    20. ¿Qué harías con un millón de pesos?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    21. ¿Cómo lidias con el estrés o situaciones de mucha presión?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    22. ¿Quién es la mujer que más admiras en tu vida y por qué?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    23. “Quien más haya menester de una cosa, quien mas ponga en ella apetencia y voluntad, ese debe de ser su dueño.” - José Vasconcelos. ¿Cómo luchas tú por tus sueños?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    24. ¿Cómo pasas tu tiempo libre?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    25. ¿Cómo te describirían tu familia y demás seres queridos?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    26. ¿Por qué quieres entrar a Girl Power Talk México? ¿Por qué te interesó?
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                   27. Por favor escribe acerca de lo siguiente en inglés: escribe una breve reflexión (300 - 400 palabras) acerca del papel que la mujer ha jugado a lo largo de la historia de México.
                  `,
          small: ``,
          answer: null,
        },
        {
          question: `
                    28. ¿Tienes preguntas que quieras hacernos? ¡Adelante! Escríbelas aquí.
                  `,
          small: ``,
          answer: null,
        },
      ],
      bar: {
        variant: "danger",
        value: 0,
      },
    };
  },
  methods: {
    async uploadFile() {},

    fireCofetti() {
      var duration = 8 * 1000;
      var animationEnd = Date.now() + duration;
      var defaults = {
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        zIndex: 999999,
      };

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      var interval = setInterval(function() {
        var timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        var particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 250);
    },

    // email method
    async sendEmail() {
      if (this.uploadedFiled != null) {
        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          }).then((resp) => {
            this.personalData.file = resp;
          });
        await toBase64(this.uploadedFiled);
        // console.log();
      }

      let respuestas = `

          1: ${this.form[0].question},
          respuesta: ${this.form[0].answer}
          <br>
          2: ${this.form[1].question},
          respuesta: ${this.form[1].answer}
          <br>
          3: ${this.form[2].question},
          respuesta: ${this.form[2].answer}
          <br>
          4: ${this.form[3].question},
          respuesta: ${this.form[3].answer}
          <br>
          5: ${this.form[4].question},
          respuesta: ${this.form[4].answer}
          <br>
          6: ${this.form[5].question},
          respuesta: ${this.form[5].answer}
          <br>
          7: ${this.form[6].question},
          respuesta: ${this.form[6].answer}
          <br>
          8: ${this.form[7].question},
          respuesta: ${this.form[7].answer}
          <br>
          9: ${this.form[8].question},
          respuesta: ${this.form[8].answer}
          <br>
          10: ${this.form[9].question},
          respuesta: ${this.form[9].answer}
          <br>
          11: ${this.form[10].question},
          respuesta: ${this.form[10].answer}
          <br>
          12: ${this.form[11].question},
          respuesta: ${this.form[11].answer}
          <br>
          13: ${this.form[12].question},
          respuesta: ${this.form[12].answer}
          <br>
          14: ${this.form[13].question},
          respuesta: ${this.form[13].answer}
          <br>
          15: ${this.form[14].question},
          respuesta: ${this.form[14].answer}
          <br>
          16: ${this.form[15].question},
          respuesta: ${this.form[15].answer}
          <br>
          17 ${this.form[16].question},
          respuesta: ${this.form[16].answer}
          <br>
          18: ${this.form[17].question},
          respuesta: ${this.form[17].answer}
          <br>
          19: ${this.form[18].question},
          respuesta: ${this.form[18].answer}
          <br>
          20: ${this.form[19].question},
          respuesta: ${this.form[19].answer}
          <br>
          21: ${this.form[20].question},
          respuesta: ${this.form[20].answer}
          <br>
          22: ${this.form[21].question},
          respuesta: ${this.form[21].answer}
          <br>
          23: ${this.form[22].question},
          respuesta: ${this.form[22].answer}
          <br>
          24: ${this.form[23].question},
          respuesta: ${this.form[23].answer}
          <br>
          25: ${this.form[24].question},
          respuesta: ${this.form[24].answer}
          <br>
          26: ${this.form[25].question},
          respuesta: ${this.form[25].answer}
          <br>
          27: ${this.form[26].question},
          respuesta: ${this.form[26].answer}
          <br>
          28: ${this.form[27].question},
          respuesta: ${this.form[27].answer}
          <br>

      `;

      let dataDelForm = `
        nombre: ${this.personalData.name},
        email: ${this.personalData.email},
        edad: ${this.personalData.age},
        número: ${this.personalData.phone_number},
      `;

      Email.send({
        Host: "smtp.elasticemail.com",
        Username: "yaquedonoreply@gmail.com",
        Password: "0EB92F70C77E23F68F6559457484B92D3384",
        To: [
          "daniel.drnts@gmail.com",
          "pablo.ruz.salmones@yaquedo.com.mx",
          "daniel.dorantes.garcia@yaquedo.com.mx",
          "pablo.ruz.salmones@gmail.com",
        ],
        From: "yaquedonoreply@gmail.com",
        Subject: `Postulación de: ${this.personalData.name}`,
        Body: `${dataDelForm} <br> ${respuestas}`,
        Attachments: [
          {
            name: `${
              this.uploadedFiled != null ? this.uploadedFiled.name : "No hay CV"
            }`,
            data: this.personalData.file,
          },
        ],
      })
        .then((message) => {
          this.dismissCountDown = this.dismissSecs;
          this.fireCofetti();
        })
        .catch((err) => {
          alert(`Ha ocurrido un error: ${err}`);
        });
    },

    // Controls the auto-save of form
    autoSaveForm() {
      this.setTheFormInStorage(formKey, this.form);
    },

    checkIfStorageExist(formKey) {
      // null if doesn't exist
      return localStorage.getItem(formKey);
    },

    setTheFormInStorage(formKey, form) {
      localStorage.setItem(formKey, JSON.stringify(form));
    },

    // Controls the progress bar
    getProgress() {
      let total_filled = 0;
      let total = this.form.length;
      let porcentaje = null;

      this.form.forEach((element) => {
        if (
          element.answer != "" &&
          element.answer != " " &&
          element.answer != null &&
          element.answer
        ) {
          total_filled = total_filled + 1;
        }
      });
      porcentaje = (100 * total_filled) / total;
      this.bar.value = porcentaje;
      this.setTheFormInStorage(formKey, this.form);
      this.setTheFormInStorage(personalDataKey, this.personalData);

      if (porcentaje == 100) {
        this.hideSubmit = false;
      } else {
        this.hideSubmit = true;
      }
    },

    cleanForm() {
      this.form.forEach((element) => {
        element.answer = null;
      });
      this.getProgress();
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (this.dismissCountDown == 0) {
        this.$refs["my-modal"].hide();
      }
    },
    showAlert() {
      this.sendEmail();
    },

    playPauseVideo() {
      this.playingVideo === true
        ? this.$refs.landingVideo.pause()
        : this.$refs.landingVideo.play();
      this.showSidebar = !this.showSidebar;
      this.playingVideo = !this.playingVideo;
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },

  mounted() {
    this.getProgress();
    // if (this.isMobile) {
    this.$refs.landingVideo.play();
    this.playingVideo = !this.playingVideo;
    // }
  },

  created() {
    this.isMobile = window.screen.width <= 375 ? true : false;

    let storageValue = this.checkIfStorageExist(formKey);
    let personalValue = this.checkIfStorageExist(personalDataKey);
    if (personalValue != null) {
      this.personalData = JSON.parse(personalValue);
    }
    if (storageValue != null) {
      this.form = JSON.parse(storageValue);
    }
  },
  destroyed() {},
};
</script>

<style scoped>
.wrapper {
  position: absolute;
  width: 100%;
}

.gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #151515e7
  );
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 100vh;
  object-fit: cover;
  position: relative;
  z-index: 2;
}
video {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
}
.title {
  height: 4em;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.completedForm {
  opacity: 1 !important;
  color: #c72323;
}
.bg-remember {
  background-color: #151515b9;
}

.form-custom-bg {
  background-color: #36b9e023;
}

.section-to-reach {
  opacity: 0.4;
  transition: opacity 2s ease-in-out;
}
.section-reached {
  opacity: 1;
}
.custom-link {
  text-decoration: none;
}
.custom-link:hover {
  color: #fff !important;
}
.custom-bg {
  background-image: linear-gradient(#e41a1ade, #e41a1ade),
    url("https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/timeline%2F2021_febrero.jpg?alt=media&token=35e9151f-32bd-451f-b650-5fa9004be8f3");
  background-position: center;
}
</style>
